import React from "react";
import Reinvent1 from "./../../images/Vonage_2-Mission-0302.png";
import Reinvent2 from "./../../images/Vonage_AboutUs-3-Manifesto_0221v2.jpeg";
import Reinvent3 from "./../../images/Vonage_AboutUs-4-Products_0220.jpeg";
import Reinvent4 from "./../../images/Vonage_5-Values-0302.png";
import Reinvent5 from "./../../images/Vonage_AboutUs-6-Stats_0220.jpeg";

function ReinventingCommunications() {
  return (
    <div>
      <div className="max-w-6xl m-auto md:py-24 py-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-16 md:mb-16 mb-8">
          <div className="md:block hidden">
            <div className="flex justify-center items-center">
              <img src={Reinvent1} alt="" className="w-80" />
            </div>
          </div>
          <div className="flex items-center">
            <div >
              <p className="text-xs font-normal leading-5 tracking-widest">
                WHO WE ARE
              </p>
              <h1 className="font-medium text-3xl mb-5">
                Reinventing communications all over again
              </h1>
              <p className="font-normal text-lg">
                Change is an essential part of the Vonage DNA. Our original
                business brought VoIP to families and small businesses. And now
                we’re making communications more flexible, intelligent, and
                personal to help enterprises the world over stay ahead. We've
                brought leaders in unified communications, contact centers, and
                communications APIs all under one roof. Together, we’ve created
                the world’s most flexible cloud communications platform.
              </p>
            </div>
          </div>
          <div className="md:hidden block">
            <div className="flex justify-center items-center">
              <img src={Reinvent1} alt="" className="w-80" />
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-16 md:mb-16 mb-8">
          <div className="flex items-center">
            <div >
              <p className="text-xs font-normal leading-5 tracking-widest">
                OUR PROMISE
              </p>
              <h1 className="font-medium text-3xl mb-5">Now we're talking</h1>
              <p className="font-normal text-lg">
                <span className="font-bold">Technology</span> is supposed to
                make things easier. Right? So, why does the business
                communications business seem to make things so complicated? It’s
                simple to us: We want you to understand our unified
                communications solutions without needing a PhD. We want to
                maximize the impact and minimize the jargon. We don’t want to
                work with celebrity spokespeople. We’re too busy working with
                businesses and developers. We don’t care about our technology
                being fancy, we want it to be so good you forget about it.
                Because it’s not about how advanced the technology is, it’s
                about what you can do with it. We’re keeping communications how
                they should be: straightforward. Now we’re talking.
              </p>
            </div>
          </div>
          <div>
            <div className="flex justify-center items-center">
              <img src={Reinvent2} alt="" className="w-80" />
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-16 md:mb-16 mb-8">
          <div className="md:block hidden">
            <div className="flex justify-center items-center">
              <img src={Reinvent3} alt="" className="w-80" />
            </div>
          </div>
          <div className="flex items-center">
            <div >
              <p className="text-xs font-normal leading-5 tracking-widest">
                OUR PRODUCTS
              </p>
              <h1 className="font-medium text-3xl mb-5">
                Unified Communications, Contact Centers, Communications APIs:
                take your pick ...
              </h1>
              <p className="font-normal text-lg">
                ... or pick them all. We’re uniquely flexible when it comes to
                our capabilities. Choose from our stock of proven applications,
                all ready to go. Select from our extensive portfolio of APIs to
                create your own apps and integrations. Or get the best of both:
                choose the apps you need and customize them with our APIs. You
                can’t do that with anyone else.
              </p>
            </div>
          </div>
          <div className="md:hidden block">
            <div className="flex justify-center items-center">
              <img src={Reinvent3} alt="" className="w-80" />
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-16 md:mb-16 mb-8">
          <div className="flex items-center">
            <div >
              <p className="text-xs font-normal leading-5 tracking-widest">
                OUR VALUES
              </p>
              <h1 className="font-medium text-3xl mb-5">What we believe</h1>
              <p className="font-normal text-lg">
                We care about our customers, our employees, and the world around
                us. We want our people to be welcoming, imaginative and
                proactive. We believe in diversity and inclusion, and we hire
                that way. We believe in putting the customer first. And we
                believe in giving back to the communities around us. Through The
                Vonage Foundation, Vonage and our employees can volunteer, give
                to charity, and support the causes we believe in. We work with
                non-profits to help our employees volunteer within our local
                communities around the world. We give our employees paid
                volunteering days, and often encourage them to support their
                favorite causes by matching their donations.
              </p>
            </div>
          </div>
          <div>
            <div className="flex justify-center items-center">
              <img src={Reinvent4} alt="" className="w-80" />
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-16 md:mb-16 mb-8">
          <div className="md:block hidden">
            <div className="flex justify-center items-center">
              <img src={Reinvent5} alt="" className="w-80" />
            </div>
          </div>
          <div className="flex items-center">
            <div >
              <p className="text-xs font-normal leading-5 tracking-widest">
                OUR PRESENCE
              </p>
              <h1 className="font-medium text-3xl mb-5">
                We’re near you, wherever you are
              </h1>
              <p className="font-normal text-lg">
                If you're wondering what we're all about, we've got a few
                distinctive facts and figures for you:
              </p>
              <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-2 grid-cols-1 mt-5">
                <ul className="list-disc lg:pl-3 pl-6">
                  <li>
                    Headquartered in New Jersey, with offices throughout the
                    United States, Europe and Asia
                  </li>
                  <li>Over 2000 employees</li>
                  <li>Over one million registered developers</li>
                </ul>
                <ul className="list-disc lg:pl-3 pl-6">
                  <li>236 US patents, and 71 patents in other countries</li>
                  <li>25 billion minutes and messages terminated yearly</li>
                  <li> We serve over 100,000 businesses worldwide</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="md:hidden block">
            <div className="flex justify-center items-center">
              <img src={Reinvent5} alt="" className="w-80" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReinventingCommunications;
