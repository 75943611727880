import React from "react";
import Headerp from "./../../images/Community-Hub.png";

function Header() {
  return (
    <div >
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid md:grid-cols-3 grid-cols-1 gap-10">
          <div className="col-span-2">
            <div className="mb-2">
              <span className="font-medium md:text-xl text-lg leading-6 tracking-wider">
                ABOUT THE NEW VONAGE
              </span>
            </div>
            <h1 className=" font-medium  lg:text-6xl md:text-5xl text-4xl mb-4 leading-none">
              Communications have profoundly changed. So have we.
            </h1>
            <a
              href="/"
              className="bg-white border-white border-2 text-black px-8 mb-6 mr-6 py-6 rounded-lg"
            >
              See Our Awards
            </a>
          </div>
          <div className="justify-self-center">
            <img src={Headerp} alt="" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
