import React from "react";
import Werewe1 from "./../../images/gartner.png";
import Werewe2 from "./../../images/Frost_Sullivan.jpeg";
import Werewe3 from "./../../images/TMC_CRM_Excellence.png";
import Werewe4 from "./../../images/Cloud_Computing.png";
import Werewe5 from "./../../images/Aragon_Research.jpeg";
import Werewe6 from "./../../images/IDC.jpeg";

function WereWinning() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:py-24 py-12 px-2">
        <div className="flex lg:flex-nowrap flex-wrap lg:justify-start justify-center">
          <div className="p-4">
            {" "}
            <img src={Werewe1} alt="" />
          </div>
          <div className="p-4">
            <img src={Werewe2} alt="" />
          </div>
          <div className="p-4">
            <img src={Werewe3} alt="" />
          </div>
          <div className="p-4">
            <img src={Werewe4} alt="" />
          </div>
          <div className="p-4">
            <img src={Werewe5} alt="" />
          </div>
          <div className="p-4">
            <img src={Werewe6} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WereWinning;
